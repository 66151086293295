<template>
    <div class="wrapper">
        <headBack :showKef='true'>
            <template v-slot:title>
                <div class='title'>
                    服务详情
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>

        <div class="gene_box">
            <img class="gene_bg" :src='detail.bimg1' alt="">
            <div class="conts">
                <div class="tit">
                    <span class="t">{{detail.title}}</span>
                    <div class="mo">
                        <span class="l">¥</span><span>{{detail.price}}</span><span class="r">/次</span>
                    </div>
                </div>
                <span class="sub" :style="{background:themeColor}">{{detail.describe}}</span>
                <div class="list">
                    <!--                    <img src="https://static.yihu365.cn/img/h5Img/assets/img/genes/bg_blue.png" alt="">-->
                    <ul class="list_box">
                        <li class="title"><span class="l_ic" :style="{background:themeColor}"></span><span>适用人群</span></li>
                        <li class="lis" v-for="(item,index) in detail.suitableCrowd" key="item"><span :style="{color:themeColor}">·</span>{{item}}
                        </li>
                        <!--                        <li class="lis"><span>·</span>长期生活不规律、作息紊乱者；</li>-->
                        <!--                        <li class="lis"><span>·</span>生活或环境污染比较严重</li>-->
                        <!--                        <li class="lis"><span>·</span>长期处于高压力工作环境者</li>-->
                    </ul>
                </div>
                <div class="list2">
                    <ul class="list_box" v-if="detail.serviceWay">
                        <li class="title"><span class="l_ic" :style="{background:themeColor}"></span>服务方法</li>
                        <li class="lis" v-for="(item,index) in detail.serviceWay.paragraph1" key="item"><span :style="{color:themeColor}">·</span>{{item}}
                        </li>
                    </ul>
                    <div v-if="detail.serviceWay">
                        <div class="line" v-if="detail.serviceWay.paragraph2[1]"></div>
                        <div class="box2">
                            <div class="wi" v-if="detail.serviceWay.paragraph2[0]">
                                <div class="man">男性专有</div>
                                <ul class="m_l">
                                    <li v-for="(item,index) in detail.serviceWay.paragraph2[0].value">{{item}}</li>
                                </ul>
                            </div>
                            <div class="wi" v-if="detail.serviceWay.paragraph2[1]">
                                <div class="lady">女性专有</div>
                                <ul class="l_l">
                                    <li v-for="(item,index) in detail.serviceWay.paragraph2[1].value">{{item}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="box3" v-if="detail.serviceWay.paragraph2[2]">
                            <div class="t3">共有项目</div>
                            <ul class="txt">
                                <li v-for="(item,index) in detail.serviceWay.paragraph2[2].value">{{item}}</li>
                            </ul>
                        </div>

                        <div class="line" v-if="detail.serviceWay.paragraph2[2]"></div>
                    </div>
                    <div v-if="detail.serviceWay"
                         v-for="(item,index) in detail.serviceWay.paragraph3"
                    >
                        <span style="color:#00A3FF;font-size: 15px;font-weight: bold; margin-right: 4px;" :style="{color:themeColor}">·</span>{{item}}
                    </div>

                </div>
                <div class="list2">
                    <ul class="list_box">
                        <li class="title"><span class="l_ic" :style="{background:themeColor}"></span>服务提示</li>
                        <li class="lis" v-for="(item,index) in detail.serviceHint" key="item"><span :style="{color:themeColor}">·</span>{{item}}
                        </li>
                    </ul>
                </div>
                <div class="list2">
                    <ul class="list_box">
                        <li class="title"><span class="l_ic" :style="{background:themeColor}"></span>检测流程</li>
                        <img style="width:100%" :src="detail.testingProcess" alt="">
                    </ul>
                </div>
                <div class="list2">
                    <ul class="list_box">
                        <li class="title"><span class="l_ic" :style="{background:themeColor}"></span>报告示例</li>
                        <div class="bgao">
                            <img :src="item" alt="" v-for="(item,index) in detail.sampleReport">
                        </div>
                    </ul>
                </div>
                <div class="list2">
                    <ul class="list_box">
                        <li class="title"><span class="l_ic" :style="{background:themeColor}"></span>温馨提示</li>
                        <li class="lis" v-for="(item,index) in detail.warmHint" key="item"><span :style="{color:themeColor}">·</span>{{item}}</li>

                    </ul>
                </div>
                <ul class="tk">
                    <li style="font-weight: bold">退款与修改</li>
                    <li v-for="(item,index) in detail.refundDescribe" :key="item">{{index+1}}、{{item}}</li>
                </ul>
<!--                <ul class="qxiao">-->
<!--                    <li style="font-weight: bold">修改、取消预约</li>-->
<!--                    <li v-for="(item,index) in detail.modification" :key="item">{{index+1}}、{{item}}</li>-->

<!--                </ul>-->
            </div>
            <!--            <div class="img_box" id="imgBox">-->
            <!--                <img src="https://static.yihu365.cn/img/h5Img/assets/img/genes/bgao3.png" alt="">-->
            <!--            </div>-->
        </div>
        <!--        <div class="content"></div>-->
        <div class='btn_btm_cont'>
            <div class='btn_inner'>
                <div class='money'><span class='small'>¥</span>{{detail.price}}<span class='small'>元起</span></div>
                <van-button type="success" color='#00C291' class='submit_btn' round @click='gotoSorderPage'>立即预约
                </van-button>
            </div>
        </div>

    </div>
</template>

<script>
    import {setWxShareDate} from '@/libs/utils.js'
    import {onMounted, ref, computed, reactive} from "vue"
    import {useRouter, useRoute} from 'vue-router'
    import {useStore} from 'vuex'
    import {Dialog} from 'vant'
    import homeApi from '@axios/home'

    export default {
        name: "genesDetail",
        setup() {
            let router = useRouter()
            const route = useRoute()
          const store = useStore()
            // if(true){
            //     let mo = (e) =>{
            //         e.preventDefault();
            //     }
            //     document.body.style.overflow='hidden';
            //     document.addEventListener("touchmove",mo,false);//禁止页面滑动
            // }else{
            //     let mo=function(e){
            //
            //         e.preventDefault();
            //
            //     };
            //     document.body.style.overflow='';//出现滚动条
            //     document.removeEventListener("touchmove",mo,false);
            //
            // }

            const scdSvCode = ref('');
            let detail = ref([]);
            let themeColor = ref('#22CF6A');
// 护士任务参数
           const Recommenduserphone = route.query.Recommenduserphone ?  route.query.Recommenduserphone : '' //护士任务参数 手机号
           const batchNumber = route.query.batchNumber ?  route.query.batchNumber : '' //护士任务参数

            const getDetail = (roleCode, firsvCode, scdsvCode) => {
                let params = {
                    // id : id,
                    rolecode: roleCode,
                    firsvcode: firsvCode,
                    scdsvcode: scdsvCode,
                    serviceType: route.query.serviceType
                }
                homeApi.goodsDetail(params).then((res) => {
                    console.log(res);
                    detail.value = res.data.content2;
                    scdSvCode.value = res.data.content2.scdsvCode ? res.data.content2.scdsvCode : '';
                    themeColor.value = res.data.content2.theme
                    setWxShareDate(`医护到家：${res.data.serviceName}`,res.data.headImg)//分享
                })
            }

            getDetail(route.query.rolecode, route.query.firsvcode, route.query.scdsvcode)

            const  gotoSorderPage = () => {
                    router.push({
                        name:"bloodSorder",
                        query:{
                            code:detail.value.firsvcode,
                            roleCode:detail.value.rolecode,
                            scdSvCode:scdSvCode.value,
                            price:detail.value.price,
                            serviceType:route.query.serviceType,
                          Recommenduserphone:Recommenduserphone,
                          batchNumber:batchNumber
                        }
                    })
            }

          // 护士推广绑定用户部分
          const setBindNurse =()=> {
            const params ={
              userId:store.state.uid,
              recommenduserphone:Recommenduserphone
            }
            homeApi.setBindUserToNurse(params).then((res) => {


              if(res.code == '0000'){
                Dialog.alert({
                  title:'温馨提示',
                  message:'恭喜您，已跟护士绑定成功',
                  theme:'round-button',
                  confirmButtonColor:'rgb(0, 194, 145)'
                })
              }
              // else{
              //   Dialog.alert({
              //     title:'温馨提示',
              //     message:'很抱歉，您已绑定过护士了',
              //     theme:'round-button',
              //     confirmButtonColor:'rgb(0, 194, 145)'
              //   })
              // }

            })
          }


          if(Recommenduserphone){
            if(store.state.token){//已经登录
              setBindNurse();
            }else{
              Dialog.alert({
                title:'温馨提示',
                message:'尊敬的用户您好，为方便给您提供更好的服务，您可以跟护士做绑定关系，点击立即绑定即可。',
                theme:'round-button',
                confirmButtonText:'立即绑定',
                confirmButtonColor:'rgb(0, 194, 145)'
              }).then(()=> {

                router.push({
                  name : 'login',
                  query : {
                    Recommenduserphone:Recommenduserphone,
                    batchNumber:batchNumber

                  }
                })
              })
            }

          }

            return {
                detail,
                scdSvCode,
                gotoSorderPage,
                themeColor,
                route
            }

        }
    }

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    * {
        touch-action: pan-y;
    }

    .gene_box {
        width: 100%;
        position: relative;
        background-color: #F2F2F2;
        box-sizing: border-box;
        color: #333333;

        .gene_bg {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0
        }

        .conts {
            font-size: 26px;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, #f2f2f2 50%);
            box-sizing: border-box;
            width: 100%;
            padding: 0 30px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            margin-top: 150px;

            .sub {
                color: #ffffff;
                margin: 20px 20px;
                padding: 8px 20px;
                display: inline-block;
                border-radius: 40px;
                background: #00A3FF;
                font-size: 24px;
            }

            .title {
                font-size: 30px;
                font-weight: bold;
                line-height: 56px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                /*background-size: 100%;*/
                .l_ic {
                    display: inline-block;
                    width: 10px;
                    height: 30px;
                    background: #00A3FF;
                    margin-right: 8px;
                    border-radius: 30px 0 30px 0;
                }

                span {
                    color: #333;
                }
            }


            .lis {
                line-height: 48px;

                span {
                    color: #00A3FF;
                    margin-right: 8px;
                    font-weight: bold;
                    font-size: 30px
                }
            }

        }

        .tit {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            .t {
                font-size: 42px;
                font-weight: 600;
                color: #333333;
            }

            .mo {
                font-size: 50px !important;
                font-family: DIN-Bold, DIN;
                font-weight: bold;

                span {
                    color: #FF7A00 !important;
                }

                .l {
                    font-size: 30px;
                }

                .r {
                    font-size: 30px;
                }
            }
        }


        .list {
            width: 100%;
            background: #ffffff;
            border-radius: 24px;
            margin-top: 20px;
            /*position: relative;*/
            /*background:url(https://static.yihu365.cn/img/h5Img/assets/img/genes/bg_blue.png) no-repeat 0 0;*/
            /*height: 300px;*/
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15000000596046448);

            .list_box {
                padding: 20px;
                box-sizing: border-box;
                background: transparent;
                /*position: absolute;*/
                /*left: 0;*/
                /*top: 0;*/
                /*z-index: 2;*/
            }

        }

        .list img {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-radius: 0 0 20px 20px;
            z-index: 0;
        }

        .list2 {
            box-sizing: border-box;
            width: 100%;
            background: #ffffff;
            border-radius: 24px;
            margin-top: 20px;
            padding: 20px; /*height:480px;*/
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15000000596046448);
            font-size: 26px;

            .list_box {
                box-sizing: border-box;
            }

            .box2 {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;

                .wi {
                    width: 50%;
                    display: flex;
                }

                .man {
                    margin-right: 20px;
                    width: 100px;
                    font-size: 20px;
                    border-radius: 4px;
                    padding: 4px;
                    height: 36px;
                    border: 1px solid rgba(122, 205, 231, 0.800000011920929);
                    color: #7ACDE7;
                    background: rgba(122, 205, 231, 0.20000000298023224);
                }

                .m_l {
                    li {
                        margin-bottom: 9px;
                        color: #0083CE;
                        font-size: 24px;
                    }
                }

                .lady {
                    margin-right: 20px;
                    width: 100px;
                    font-size: 20px;
                    border-radius: 4px;
                    padding: 4px;
                    height: 36px;
                    border: 1px solid rgba(255, 109, 104, 0.800000011920929);
                    color: #FF6C68;
                    background: rgba(255, 109, 104, 0.20000000298023224);
                }

                .l_l li {
                    margin-bottom: 18px;
                    color: #FF6C68;
                    font-size: 24px;
                }

            }

            .box3 {
                font-size: 24px;
                margin-top: 20px;
                display: flex;

                .txt {
                    display: flex;
                    flex-grow: 1;
                    flex-wrap: wrap;

                    li {
                        width: 33%;
                        line-height: 50px;
                    }
                }

                /*ul {*/
                /*    margin-right: 60px;*/
                /*    li {*/
                /*        margin-bottom: 18px;*/
                /*    }*/
                /*}*/

                .t3 {
                    min-width: 100px;
                    margin-right: 20px;

                    font-size: 24px;
                    border-radius: 4px;
                    padding: 4px;
                    height: 36px;
                    border: 1px solid rgba(0, 163, 255, 0.800000011920929);
                    color: #00A3FF;
                    background: rgba(0, 163, 255, 0.20000000298023224);
                }
            }

            .bgao {
                display: flex;
                justify-content: space-between;
            }

            .bgao img {
                width: 32%;
            }


        }

    }

    .conts .tk {
        margin: 20px 0;
        color: #666666;
        font-size: 24px;
        padding: 0 12px 120px;
    }

    .conts .tk li {
        line-height: 40px;
    }

    .conts .qxiao {
        margin: 20px 0;
        color: #666666;
        font-size: 24px;
        padding: 0 12px 140px 12px;
    }

    .conts .qxiao li {
        line-height: 40px;
    }

    .img_box {
        position: fixed;
        background: rgba(0, 0, 0, 0.75);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 10000000;
        display: flex;
        align-items: center;
    }

    .img_box img {
        width: 100%;
    }


    .line {
        width: 100%;
        margin: 10px auto;
        height: 1px;
        border: none;
        border-top: 1px solid #6DD0FF;
    }

    .btn_btm_cont {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px 0;
        // display: flex;
        // justify-content: flex-end;
        background: #fff;
        z-index: 999;
        border-top: 1px solid #f5f5f5;

        .btn_inner {
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .order_btn {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding: 0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left: 20px;
        }

        .money {
            margin-right: 40px;
            color: #FF6161;
            font-size: 45px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }

        .small {
            font-size: 28px;
            margin: 0 5px;
        }

        .submit_btn {
            width: 200px;
            height: 80px;
            line-height: 80px;
          white-space: nowrap;
        }
    }
</style>
